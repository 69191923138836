/* Reset cursor */
.Toastify__toast {
    cursor: auto;
    max-height: none;
}

/* overwrite toasty width to 800 */
.Toastify__toast-container {
    width: 800px;
}

.Toastify__toast.workflow-error-notification {
    padding: 0;
    color: white;
}
.Toastify__toast.workflow-error-notification button {
    color: #fefefe;
    opacity: 0.8;
    position: absolute;
    right: 5px;
    top: 5px;
}
.Toastify__toast.workflow-error-notification button:hover,
.Toastify__toast.workflow-error-notification button:active {
    opacity: 1;
}
